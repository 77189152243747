
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';

import { mdiChevronUp, mdiFormatQuoteClose } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Ansprechpartner | Styria Media Group',
      keywords: ['kontakt', 'karriere', 'ansprechpartner', 'hr', 'human resources', 'styria'],
      description:
        'Wie gut, zu wissen, wohin man sich wenden kann! Hier gibt es alle Ansprechpartner:innen rund um Jobsuche und Bewerbung auf einen Blick.',
      image: require('@/assets/img/og/karriere.png'),
    },
    en: {
      title: 'contact | Styria Media Group',
      keywords: ['contact', 'career', 'contact person', 'hr', 'human resources', 'styria'],
      description: 'How good it is to know where to turn! Here you can find all contact persons for job search and application at a glance.',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer,
  },
  setup() {
    return {
      mdiChevronUp,
      mdiFormatQuoteClose,
    };
  },
});
